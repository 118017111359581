<script>
import http from '@/http'
import { reactive, ref, } from 'vue'
import { useRoute } from 'vue-router'

export default {
  setup() {
    let route = useRoute()
    let info = reactive({})
    let imgShow = ref(false)
    http.post('/?cmd=com.xqxc.api.pigear.biz.platform.MerchantAboutUsService.getAboutUs', {
      version: route.query.version  || 'v1.0.0'
    }, {

    }).then(data => {
      if(data.result) {
        for(let item in data.result) {
          info[item] = data.result[item]
          imgShow.value = true
        }
      }
      
    })
    return {
      info,
      imgShow
    }
  }

}
</script>
<template>
  <div class="aboutContainer" style="text-align:center; padding:1.8rem 1rem">
    <p><img style="width:2rem; height:2rem; margin:.3rem 0" :src="info.icon" v-if="imgShow" /></p>
    <p style="font-weight:bold">{{info.appName}}</p>
    <p>当前版本：{{info.version}}</p>
    <p style="text-align:left;word-break: break-all;line-height:1.5">{{info.aboutUs}}</p>
    <div class="aboutFooter">
      <a style="color: #333;" href="https://www.lazipig.com/" target="_blank">懒猪科技（重庆）有限公司</a>
    </div>
  </div>
</template>
<style>
  div p{  margin-bottom:.4rem }
  .aboutContainer{
    min-height: 100vh;
    box-sizing: border-box;
    position: relative;
  }
  .aboutFooter{
    position: absolute;
    bottom: 1.3rem;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }
</style>
